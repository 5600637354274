import { getBasicWebApiData, getFooterApiData } from '@/apis/layout'
import { baseLayoutStore } from '@/store/layout'
import { TlayoutProps } from '@/typings/api/layout'
import {
  YapiGetV1HomeWebsiteGetData,
  YapiGetV1HomeWebsiteGetDataApiRequest,
} from '@/typings/yapi/HomeWebsiteGetDataV1GetApi'
import { fetchChainStarBottomBar } from '@/apis/home'
import { omit } from 'lodash'
import { YapiGetV1ChainStarBottomBarApiRequest } from '@/typings/yapi/ChainStarBottomBarV1GetApi'
import { businessId } from '../env'
import { extractFooterData } from './footer'

async function getLayoutPropsWithNavigation(
  lanType: YapiGetV1ChainStarBottomBarApiRequest['lanType']
): Promise<TlayoutProps | undefined> {
  if (businessId) {
    const params = { businessId, lanType }
    const req = Promise.all([fetchChainStarBottomBar(params), getFooterApiData(params)])
    const res = await req
    return res[0]?.data || res[1]?.data
      ? {
          ...res[0]?.data,
          ...res[1]?.data,
        }
      : undefined
  }
}

async function getLayoutPropsMetaDataOnly(
  lanType: YapiGetV1HomeWebsiteGetDataApiRequest['lanType']
): Promise<YapiGetV1HomeWebsiteGetData | undefined> {
  if (businessId) return (await getBasicWebApiData({ businessId, lanType })).data
}

async function getLayoutProps(
  lanType: YapiGetV1HomeWebsiteGetDataApiRequest['lanType']
): Promise<YapiGetV1HomeWebsiteGetData | TlayoutProps | undefined> {
  return await getLayoutPropsWithNavigation(lanType)
}

function initializeLayoutStore(pageContext) {
  const layoutStore = baseLayoutStore.getState()
  const { setHeaderData, setLayoutProps, setWebFooterData, setH5FooterData } = layoutStore
  const { layoutProps } = pageContext
  setLayoutProps(layoutProps)
  setHeaderData(omit(layoutProps, ['list']))
  // const headerData = extractHeaderData(layoutProps)
  const { webFooter, h5Footer } = extractFooterData(layoutProps) || {}
  h5Footer && setH5FooterData(h5Footer)
  webFooter && setWebFooterData(webFooter)
  // setColumnsDataByCd(recursiveColumnMap(layoutProps?.columnsDatas || []))
  // setHeaderData(headerData)
  // setFooterData(footerData)
}
export { getLayoutProps, initializeLayoutStore }
