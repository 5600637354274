import Icon from '@/components/icon'
import { useEffect, useState, useRef } from 'react'
import { t } from '@lingui/macro'
import { useLockBodyScroll } from 'react-use'
import { link } from '@/helper/link'
import { usePageContext } from '@/hooks/use-page-context'
import { Tooltip, Modal } from '@arco-design/web-react'
import { getDownloadPath } from '@/helper/route/homepage'
import { useUserStore, baseUserStore } from '@/store/user'

import { accountRouting, getCreatePath, logIn } from '@/helper/route/account'
import { useDownloadStore } from '@/store/download'
import styles from './index.module.css'
import { I18nSelect } from './components/personalization'
import { SVGLogo } from './components/logo'
import MenuMask from './components/menu-mask'
import UserNav from './components/user-nav'

function Header() {
  const { disposition } = useDownloadStore()
  const [delNum, serDelNum] = useState<string>('')
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  useLockBodyScroll(popupVisible)
  const { path } = usePageContext()
  const { accountInformation } = accountRouting()
  const { token } = baseUserStore.getState()
  const [visible, setVisible] = useState(false) // 弹窗变量
  const { clearUserCacheData, selectedMenuItem, setSelectedMenuItem } = useUserStore()
  const [tokenHandle, settokenHandle] = useState(false)
  const [getTokenCaches, settGetTokenCaches] = useState(true)
  const herderDom = useRef<HTMLDivElement>(null)
  const MenuItem = {
    HOME: '/',
    DOWNLOAD: getDownloadPath(),
    ACCOUNT: accountInformation,
  }
  useEffect(() => {
    settokenHandle(!!token)
    settGetTokenCaches(!!token)
  }, [token])
  /**
   * 登陆事件
   */
  const onLogin = () => {
    link(logIn())
  }

  /**
   * 跳转事件
   */
  const onTab = url => {
    if (delNum !== url) {
      link(url)
    }
  }

  /**
   * 更多设备
   */
  const onMoreDevices = () => {
    if (token) {
      setVisible(true)
      return
    }
    onTab('/register/create-account')
  }

  /**
   * 推出事件
   * */
  const onSubOut = async () => {
    await clearUserCacheData()
    setVisible(false)
    link('/register/create-account')
  }

  useEffect(() => {
    setSelectedMenuItem(path)
    setPopupVisible(false)
    serDelNum(path)
  }, [path])

  // 关闭菜单窗口
  const closeMask = () => {
    setShowMenu(false)
  }

  return (
    <div className={styles['header-menus-wrap']}>
      <div className="header-box" ref={herderDom}>
        <div
          className="home-wrap"
          onClick={() => {
            setShowMenu(false)
          }}
        >
          <div
            className="inline"
            onClick={() => {
              setSelectedMenuItem(MenuItem.HOME)
            }}
          >
            <SVGLogo />
          </div>
          <div className="home-header-left">
            <div
              className={`item-text ${selectedMenuItem === MenuItem.HOME ? 'active' : ''}`}
              onClick={() => {
                setSelectedMenuItem(MenuItem.HOME)
                onTab(MenuItem.HOME)
              }}
            >
              {t`modules_index_page_server_xepdt2p6kgqvmd7vxr7wq`}
            </div>

            <div
              className={`item-text ${selectedMenuItem === MenuItem.DOWNLOAD ? 'active' : ''}`}
              onClick={() => {
                setSelectedMenuItem(MenuItem.DOWNLOAD)
                onTab(MenuItem.DOWNLOAD)
              }}
            >
              {t`features_layout_header_belqrrslkz`}
            </div>

            {token && (
              <div
                className={`item-text ${selectedMenuItem === MenuItem.ACCOUNT ? 'active' : ''}`}
                onClick={() => {
                  setSelectedMenuItem(MenuItem.ACCOUNT)
                  onTab(MenuItem.ACCOUNT)
                }}
              >
                {t`features_layout_header_yloc911jxm`}
              </div>
            )}
            <div className="item-text">
              <Tooltip
                content={
                  <div className={styles['herder-tooltip']} onClick={onMoreDevices}>
                    {t`features_layout_header_9kflmgn1qq`}
                  </div>
                }
              >
                <span className="icon-drop">
                  <span className="icon-item mr-2">{t`More`}</span>
                  <Icon name="icon_drop_down" />
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          {!token && !getTokenCaches && (
            <div className="header-span">
              <div
                className={`header-span-login`}
                onClick={() => {
                  onLogin()
                }}
              >
                {t`features_user_operate_login_index_fudwh5lkfn`}
              </div>
              <div
                className={`hearder-span-box hearder-span-selected`}
                onClick={() => {
                  onTab(getCreatePath())
                }}
              >{t`features_layout_components_personalization_jcjm2uey5y`}</div>
            </div>
          )}
        </div>
        <div className="mobile-language">
          {tokenHandle && (
            <div className="user-nav-box">
              {<UserNav />}
              <div className="parting-line"></div>
            </div>
          )}
          <I18nSelect popupAlign={{ bottom: 37 }} />
          <Icon name="icon_h5_toolbar" onClick={() => setShowMenu(!showMenu)} className="icon-h5-toolbar" />
        </div>
      </div>
      <Modal
        footer={null}
        visible={visible}
        closable={false}
        className={styles['herder-modal']}
        style={{ backgroundColor: '#2F353D' }}
      >
        {/* <div className="modal-icon">
          <Icon name="icon_close" onClick={() => setVisible(false)} />
        </div> */}
        <div>
          <div className="herder-modal-box">
            <div className="relative -top-2">
              <Icon name="icon_tips" />
            </div>
            <div className="herder-model-item">
              {t`features_layout_header_e7ycyvaotg`}
              {disposition?.businessName || 'High VPN'} {t`features_layout_header_6e2g0ogole`}
            </div>
          </div>
          <div className="herder-model-button">
            <div
              className="herder-model-text mr-6"
              style={{ backgroundColor: '#999999' }}
              onClick={() => setVisible(false)}
            >{t`features_account_listdevices_index_5qhjv3sli2`}</div>
            <div className="herder-model-text" onClick={() => onSubOut()}>
              {t`components_subscribe_modal_index_db4_xg16ry`}
            </div>
          </div>
        </div>
      </Modal>
      <MenuMask showMask={showMenu} closeMask={closeMask} />
    </div>
  )
}

export default Header
