import { Message, Trigger } from '@arco-design/web-react'
import Icon from '@/components/icon'
import { useState } from 'react'
import { link } from '@/helper/link'
import classNames from 'classnames'
import { usePageContext } from '@/hooks/use-page-context'
import { t } from '@lingui/macro'
import { useRequest } from 'ahooks'
import { postUserLogoutOut } from '@/apis/login'
import { useUserStore } from '@/store/user'
import { accountRouting } from '@/helper/route/account'
import styles from './user-nav.module.css'

export default function UserNav() {
  const { accountInformation } = accountRouting() // 获取路由定义值
  const { clearUserCacheData, setSelectedMenuItem } = useUserStore()
  const [visible, setVisible] = useState(false)
  const pageContext = usePageContext()
  const userNavMap = [
    {
      icon: 'icon_account_information',
      text: t`features_account_components_index_y1ht_vrnwt`,
      url: accountInformation,
    },
    { icon: 'icon_recharge_record', text: t`features_account_index_5vj0181n8f`, url: '/account/history' },
    { icon: 'icon_help_center', text: t`features_account_components_index_w7trreo19t`, url: '/help-center' },
  ]
  /**
   * 退出登陆
   */
  const { run: onOut, loading } = useRequest(
    async () => {
      const res = await postUserLogoutOut({})
      if (res.isOk && res.data) {
        await clearUserCacheData()
        Message.success(t`features_account_components_index_xkm2ysurjx`)
        link('/login')
      }
    },
    { manual: true }
  )
  return (
    <Trigger
      trigger="click"
      popupVisible={visible}
      onVisibleChange={v => {
        setVisible(v)
      }}
      popupAlign={{ top: 50 }}
      position={'bottom'}
      popup={() => {
        return (
          <div className={styles['user-nav']}>
            {userNavMap.map((item, index) => {
              let btnSelected
              if (index === 0) {
                btnSelected = 'btn-zh'
              } else {
                btnSelected = 'btn-cz'
              }

              return (
                <div
                  key={item.url}
                  onClick={() => {
                    link(item.url)
                    setSelectedMenuItem(item.url)
                    setVisible(false)
                  }}
                  className={classNames('nav-btn', btnSelected, item.url === pageContext.path && 'nav-btn-selected')}
                >
                  <Icon name={item.icon} fontSize={20} />

                  <span className="ml-3">{item.text}</span>
                </div>
              )
            })}
            <div
              className="log-out-btn"
              onClick={() => {
                // link('/login')
                onOut()
                setVisible(false)
              }}
            >
              <div className="log-out-text">{t`features_account_components_index_dhqdagitgj`}</div>
            </div>
          </div>
        )
      }}
    >
      <div className={styles['user-nav-box']}>
        <Icon name="icon_ersonal_center" className="icon-ersonal-center-icon" />
      </div>
    </Trigger>
  )
}
