import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { newbitEnv, envIsBuild } from '../../env'

export function initSentry() {
  if (envIsBuild && ['test', 'production'].includes(newbitEnv)) {
    Sentry.init({
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
        // `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
        // `Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
      ],
      dsn: 'https://ed85a26fcac8f118ab1bc503596ff5fc@o4504779517198336.ingest.sentry.io/4505797304713216',
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      environment: newbitEnv,
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: getReplaysSessionSampleRate(), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
function getReplaysSessionSampleRate() {
  if (['test'].includes(newbitEnv)) return 0.5
  return 0.1
}
