import React from 'react'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { Layout as ArcoLayout, ConfigProvider } from '@arco-design/web-react'
import zhCN from '@arco-design/web-react/lib/locale/zh-CN'
import zhHK from '@arco-design/web-react/lib/locale/zh-HK'
import enUS from '@arco-design/web-react/lib/locale/en-US'
import Header from '@/features/layout/header'
import { I18nsEnum } from '@/constants/i18n'
import { PageContextProvider } from '@/hooks/use-page-context'
import ErrorBoundary from '@/components/error-boundary'
import Footer from '@/features/layout/footer'
// import SideBar from '@/features/layout/side-bar'
import type { ComponentConfig } from '@arco-design/web-react/es/ConfigProvider/interface'
import LoadingElement from '@/components/loading-element'
import ListEmpty from '@/components/list-empty'

import 'animate.css'
import { isApp } from '@/helper/is-app'

export default Layout

const ArcoLayoutHeader = ArcoLayout.Header
const ArcoLayoutFooter = ArcoLayout.Footer
const ArcoLayoutContent = ArcoLayout.Content

const arcoComponentConfig: ComponentConfig = {
  Spin: {
    element: <LoadingElement />,
  },
  Table: {
    noDataElement: <ListEmpty />,
  },
}

function Layout({ pageContext, children }: { pageContext: PageContext; children: React.ReactNode }) {
  const isFullScreen = pageContext.layoutParams?.fullScreen
  const headerShow = pageContext.layoutParams?.headerShow
  const footerShow = pageContext.layoutParams?.footerShow

  const locale = pageContext.locale

  function getLocale(localeVal?: string) {
    switch (localeVal) {
      case I18nsEnum['zh-CN']:
        return zhCN

      case I18nsEnum['en-US']:
        return enUS

      case I18nsEnum['zh-HK']:
        return zhHK

      default:
        return zhCN
    }
  }

  return (
    <PageContextProvider pageContext={pageContext}>
      <I18nProvider i18n={i18n}>
        <ConfigProvider componentConfig={arcoComponentConfig} locale={getLocale(locale)}>
          <div id="layout" className={isFullScreen ? 'layout-fullscreen-wrap' : 'layout-wrap'}>
            <ErrorBoundary>
              {headerShow && !isApp() ? (
                <ArcoLayoutHeader className="sticky top-0 left-0 z-20 shadow-sm">
                  <Header />
                </ArcoLayoutHeader>
              ) : null}
              <ArcoLayoutContent>{children}</ArcoLayoutContent>
              {/* <ArcoLayoutContent>
                <SideBar />
              </ArcoLayoutContent> */}
              {footerShow && !isApp() ? (
                <ArcoLayoutFooter>
                  <Footer />
                </ArcoLayoutFooter>
              ) : null}
            </ErrorBoundary>
          </div>
        </ConfigProvider>
      </I18nProvider>
    </PageContextProvider>
  )
}
