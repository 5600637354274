import Icon from '@/components/icon'
import { useEffect, useState } from 'react'
import { Menu, Slider } from '@arco-design/web-react'
import { link } from '@/helper/link'
import { baseUserStore } from '@/store/user'
import { t } from '@lingui/macro'
import { usePageContext } from '@/hooks/use-page-context'
import classNames from 'classnames'
import { isCommon } from '@/helper/common'
import styles from './menu-mask.module.css'

const MenuItem = Menu.Item
const SubMenu = Menu.SubMenu

// 擦弹跳转枚举地址
enum menuIteMenum {
  'homepage' = '/', // 跳转首页
  'product' = '/homepage/download', // 跳转至下载产品
  'login' = '/login', // 跳转至登录注册页
  'more' = '/register/create-account', // 更多
  'account' = '/account/information', // 个人账户
}
enum menuKey {
  'homepage' = '0',
  'product' = '1',
  'account' = '2',
}

function MenuMask(props) {
  const pageContext = usePageContext()
  const { showMask, closeMask } = props
  const { token } = baseUserStore.getState()
  const [selectedKey, setSelectedKey] = useState('0')
  useEffect(() => {
    const path = pageContext.path

    if (path === menuIteMenum.homepage) {
      setSelectedKey(menuKey.homepage)
    }
    if (path.includes(menuIteMenum.product)) {
      setSelectedKey(menuKey.product)
    }
    if (path === menuIteMenum.account || path === menuIteMenum.login || path === menuIteMenum.more) {
      setSelectedKey(menuKey.account)
    }
  }, [pageContext])
  /*
  跳转链接
  */
  const toGoPath = (route: string) => {
    link(menuIteMenum[route])
    closeMask()
  }
  return (
    <div className={styles['menu-mask']} style={{ display: showMask ? 'block' : 'none' }}>
      <div className={classNames('left-ball', isCommon() && 'ball-com')}></div>
      <div className={classNames('right-ball', isCommon() && 'ball-com')}></div>
      <div className="menu-box">
        <Menu
          style={{ width: '100%', height: 'calc(100% - 28px)' }}
          defaultOpenKeys={['0']}
          selectedKeys={[selectedKey]}
          defaultSelectedKeys={['0']}
          icons={{ horizontalArrowDown: <Icon name="icon_drop_down" className="menu-icon-drop-down" /> }}
        >
          <MenuItem
            key="0"
            onClick={() => {
              toGoPath('homepage')
            }}
          >
            {t`modules_index_page_server_xepdt2p6kgqvmd7vxr7wq`}
          </MenuItem>
          <MenuItem
            key="1"
            onClick={() => {
              toGoPath('product')
            }}
          >
            {t`features_layout_header_belqrrslkz`}
          </MenuItem>
          {token ? (
            <MenuItem
              key="2"
              onClick={() => {
                toGoPath('account')
              }}
            >
              {t`features_layout_header_yloc911jxm`}
            </MenuItem>
          ) : (
            <MenuItem
              key="2"
              onClick={() => {
                toGoPath('login')
              }}
            >
              {t`features_user_operate_login_index_fudwh5lkfn`}/
              {t`features_layout_components_personalization_jcjm2uey5y`}
            </MenuItem>
          )}

          <SubMenu key="3" title={<> {t`More`}</>}>
            <MenuItem
              key="3_0"
              onClick={() => {
                toGoPath('more')
              }}
            >
              {t`features_layout_header_9kflmgn1qq`}
            </MenuItem>
          </SubMenu>
        </Menu>
      </div>
    </div>
  )
}

export default MenuMask
