import ReactDOMClient from 'react-dom'
import { ThemeEnum } from '@/constants/base'
import { onInstallForApp } from '@/helper/lifecycle'
import { onInstallForClient } from '@/helper/lifecycle/client'
import { baseCommonStore } from '@/store/common'
import { getSeo } from '@/helper/seo'
import AsyncSuspense from '@/components/async-suspense'
import { getRedirectUrl } from '@/helper/auth'
import { link } from '@/helper/link'
import Layout from './layout'
import '@/style/layout.css'

export const clientRouting = true
export const prefetchStaticAssets = { when: 'VIEWPORT' }
export const hydrationCanBeAborted = true

async function render(pageContext: PageContext) {
  const { Page, pageProps, needSeo, authTo, unAuthTo } = pageContext

  const redirectUrl = getRedirectUrl(authTo, unAuthTo)
  const isRedirectTo = !!redirectUrl
  const appLayout = needSeo ? (
    <Layout pageContext={pageContext}>{!isRedirectTo && <Page {...pageProps} />}</Layout>
  ) : (
    <Layout pageContext={pageContext}>
      <AsyncSuspense>{!isRedirectTo && <Page {...pageProps} />}</AsyncSuspense>
    </Layout>
  )

  const container = document.getElementById('page-view')!
  if (pageContext.isHydration) {
    await onInstallForApp(pageContext)
    await onInstallForClient(pageContext)
    ReactDOMClient.hydrate(appLayout, container)
    if (isRedirectTo) {
      link(redirectUrl, { overwriteLastHistoryEntry: true })
    }
    return
  }

  ReactDOMClient.render(appLayout, container)
  const { title = '', description = '' } = pageContext.documentProps || {}
  document.title = title
  document?.querySelector('meta[name="description"]')?.setAttribute('content', description)
  const commonStore = baseCommonStore.getState()
  const theme = commonStore.theme
  document.body.setAttribute('theme', theme || ThemeEnum.light)

  if (isRedirectTo) {
    link(redirectUrl, { overwriteLastHistoryEntry: true })
  }
}

function onHydrationEnd() {}

export { render, onHydrationEnd }
