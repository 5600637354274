import { t } from '@lingui/macro'
import { Button, Trigger } from '@arco-design/web-react'
import { link } from '@/helper/link'
import { setLocale } from '@/helper/i18n'
import Icon from '@/components/icon'
import LazyImage from '@/components/lazy-image'
import { useUserStore } from '@/store/user'
import { useCommonStore } from '@/store/common'

import { oss_area_code_image_domain_address } from '@/constants/oss'
import { I18nsMap, I18nsEnum, areaCodeImageMap } from '@/constants/i18n'

import { useState } from 'react'
import classNames from 'classnames'
import { useLockBodyScroll } from 'react-use'
import { useLightningOpenStation } from '@/store/lightning-open-station'
import { useMount } from 'ahooks'
import styles from './personalization.module.css'

type I18nSelectProps = {
  popupAlign?: {
    left?: number | [number, number]
    right?: number | [number, number]
    top?: number | [number, number]
    bottom?: number | [number, number]
  }
  position?: any
}

export function I18nSelect({ popupAlign, position }: I18nSelectProps) {
  const { locale } = useCommonStore()

  const changeI18n = (lang: I18nsEnum) => {
    if (lang === locale) {
      return
    }
    setLocale(lang)
  }
  const [visible, setVisible] = useState(false)
  useLockBodyScroll(visible)

  return (
    <Trigger
      trigger={'click'}
      popupVisible={visible}
      onVisibleChange={setVisible}
      popupAlign={popupAlign}
      position={position}
      popup={() => {
        return (
          <div className={styles['trigger-popup']}>
            <div className="container">
              {Object.keys(I18nsMap).map(lang => (
                <div
                  key={lang}
                  className={classNames('i18n-item', {
                    selected: lang === locale,
                  })}
                  onClick={() => changeI18n(lang as I18nsEnum)}
                >
                  {I18nsMap[lang]}
                </div>
              ))}
            </div>
          </div>
        )
      }}
    >
      <div className={styles['i18n-select-box']}>
        {/* <LazyImage className="area-icon" src={`${oss_area_code_image_domain_address}${areaCodeImageMap(locale)[1]}`} /> */}
        <Icon name="icon_language" className="icon-language" />
        <span className="text">{areaCodeImageMap(locale)[0]}</span>
        <Icon
          className={classNames('icon', {
            'rotate-180': visible,
          })}
          name="icon_drop_down"
        />
      </div>
    </Trigger>
  )
}

export function PersonSelect({ popupAlign, position, style }: I18nSelectProps & Partial<Record<'style', any>>) {
  const { clearUserCacheData } = useUserStore()
  const { getOrderStatusData, orderStatus } = useLightningOpenStation()

  const [visible, setVisible] = useState<boolean>(false)

  const [selectedKey, setSelectedKey] = useState<string>('')

  let lightningLink
  if (orderStatus?.openStationStatusPage === 1) {
    lightningLink = '/lightning-open-station'
  }
  if (orderStatus?.openStationStatusPage === 2) {
    lightningLink = '/lightning-open-station/pay'
  }
  if (orderStatus?.openStationStatusPage === 3) {
    lightningLink = 'lightning-open-station/station-underway'
  }
  if (orderStatus?.openStationStatusPage === 4) {
    lightningLink = 'lightning-open-station/accomplish'
  }

  const setVisibleChange = e => {
    setVisible(e)
  }

  const setSelectedChange = navigate => {
    if (navigate?.key === 'loginout') {
      clearUserCacheData()
    }
    setSelectedKey(navigate?.key)
    setVisible(false)
    link(navigate?.url)
  }

  useLockBodyScroll(visible)

  const triggerElementObj = [
    {
      name: t`features_chain_star_profile_index_uyv3sxjbly`,
      key: 'personal',
      icon: 'person_drop_nor',
      selecticon: 'person_drop_pre1',
      url: '/profile',
    },
    {
      name: t`features_layout_components_personalization_rdhvyooj1t`,
      key: 'lightning',
      icon: 'open_station_nor1',
      selecticon: 'open_station_pre',
      url: lightningLink,
    },
    {
      name: t`features_chain_star_order_center_index_pdibp4seq4`,
      key: 'order',
      icon: 'order_nor1',
      selecticon: 'order_pre1',
      url: '/order-center',
    },
    {
      name: t`features_layout_components_personalization_hzdzpkd2id`,
      key: 'loginout',
      icon: 'exit_nor',
      selecticon: 'exit_pre',
      url: '/',
    },
  ]

  useMount(getOrderStatusData)

  return (
    <Trigger
      trigger="click"
      popupVisible={visible}
      onVisibleChange={setVisibleChange}
      style={style}
      popupAlign={popupAlign}
      position={position}
      popup={() => {
        return (
          <div className={styles['trigger-popup']}>
            <div className="container">
              {triggerElementObj.map(item => (
                <div
                  key={item?.key}
                  className={classNames('i18n-item', {
                    selected: selectedKey === item?.key,
                  })}
                  onClick={() => {
                    setSelectedChange(item)
                  }}
                >
                  <Icon className="mt-0.5" name={selectedKey === item?.key ? item?.selecticon : item?.icon} />
                  <span
                    className={classNames('text-xs lg:text-base ml-4', {
                      'text-white': selectedKey === item?.key,
                      'text-[#878787]': selectedKey !== item?.key,
                    })}
                  >
                    {item?.name}
                  </span>
                  {item?.key === 'lightning' && (
                    <span className="text-white py-0.5 px-1 bg-[#E95A5C] rounded-sm ml-2 text-xs">HOT</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      }}
    >
      <div className={styles['i18n-select-box']}>
        <Icon name="person_tab_icon1" className="mt-0.5 text-base" />
      </div>
    </Trigger>
  )
}

function Personalization() {
  const { isLogin } = useUserStore()

  return (
    <div className={styles.scoped}>
      <div className="text-white text-base cursor-pointer" onClick={() => link('/contact')}>
        {t`features_layout_footer_5101300`}
      </div>
      <I18nSelect popupAlign={{ bottom: 37 }} />
      {isLogin ? (
        <PersonSelect popupAlign={{ bottom: 37 }} />
      ) : (
        <>
          <div
            className="px-8 text-white text-base cursor-pointer flex items-center"
            onClick={() => link('/login')}
          >{t`features_chain_star_contact_info_index_e1yu85qr0v`}</div>
          <Button
            className="contact-us"
            onClick={() => link('/register/register-homepage')}
          >{t`features_layout_components_personalization_jcjm2uey5y`}</Button>{' '}
        </>
      )}
    </div>
  )
}

export default Personalization
