/**
 * 列表空数据
 */
import { t } from '@lingui/macro'
import { Empty } from '@arco-design/web-react'
import { oss_svg_image_domain_address } from '@/constants/oss'
import styles from './index.module.css'

interface ListEmptyProps {
  imgeName?: string
  text?: string
  loading?: boolean
}

function ListEmpty(props: ListEmptyProps) {
  const { imgeName, text, loading = false } = props
  if (loading) return null
  return (
    <Empty
      className={styles.scoped}
      // imgSrc={`${oss_svg_image_domain_address}vpn/${imgeName || 'no_data'}.png`}
      // description={text || t`help.center.support_05`}
    />
  )
}
export default ListEmpty
